import React, { Component, Fragment } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import Modal from "react-bootstrap/Modal";
import Metadata from "../../Metadata/Metadata";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import activity from "../../../services/activity";
//this file is created by rishabh 11/05/22 IST @coderrishabhdubey@gmail.com

class ActionBoutonView extends Component {
  state = {
    showMetadata: false,
  };

  static defaultProps = {
    hiddenMetadata: true,
    hiddenCreateDicom: false,
  };

  setMetadata = () => {
    this.setState({
      showMetadata: !this.state.showMetadata,
    });
  };

  handleClick = (e) => {
    e.stopPropagation();
  };

  LogActivity = (type) => {
    activity.create_activity(type);
  };

  openWSI=()=>{
    let study_id=this.props.osimis_link.split('study=')[1];
    if(study_id){
      fetch('/api/studies/'+study_id,{
        method:'GET'
    })
    .then(res=>res.json())
    .then(res=>{
      let url="https://demosvr.padimedical.com/wsi/app/viewer.html?series="+res?.Series?.[0];
      window.open(url, '_blank').focus();
    })
    }
  
}
  render = () => {
    return (
      <Fragment>
        {/*modal pour metadata*/}
        <Modal
          show={this.state.showMetadata}
          onHide={this.setMetadata}
          scrollable={true}
        >
          <Modal.Header closeButton>
            <Modal.Title>Metadata</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Metadata serieID={this.props.orthancID} />
          </Modal.Body>
        </Modal>

        <Dropdown
          onClick={this.handleClick}
          drop="left"
          className="text-center"
        >
          <Dropdown.Toggle
            style={{ background: "#4CBCD2" }}
            variant="button-dropdown btn otjs-button otjs-button-blue"
            id="dropdown-basic"
            className="button-dropdown button-dropdown-green"
          >
            View
          </Dropdown.Toggle>

          <Dropdown.Menu className="mt-2 border border-dark border-2">
            {/* tukar */}
          {<a
              style={{ textDecoration: "none" }}
              href={this.props.radiant}
              target="_blank"
              onClick={() => this.LogActivity("VIEW RADIANT")}
            >
              <button type="button" className="dropdown-item ">  {/*tukar viewer - disable viewer */}
                RADIANT  
              </button>
    </a>}

           { <a
              style={{ textDecoration: "none" }}
              href={this.props.osirix}
              target="_blank"
            >
              <button
                className="dropdown-item "
                type="button"
                onClick={() => this.LogActivity("VIEW HOROS")}
                hidden={this.props.hiddenDelete}
              >
                HOROS
              </button>
  </a>}

            <a
              style={{ textDecoration: "none" }}
              href={this.props.osimis_link}
              onClick={() => this.LogActivity("VIEW OSIMIS")}
              target="_blank"
            >
              <button
                className="dropdown-item "
                type="button"
                hidden={this.props.hiddenDelete}
              >
                OSIMIS Viewer
              </button>
            </a>
            {this.props.role.view_wsi && <a
              style={{ textDecoration: "none" }}
              onClick={() => {
                this.LogActivity("VIEW WSI")
                this.openWSI()}
              }
            
            >
              <button
                className="dropdown-item "
                type="button"
                hidden={this.props.hiddenDelete}
              >
                WSI Viewer
              </button>
            </a>}
            


            <Link
              style={{ textDecoration: "none" }}
              to={{ pathname: this.props.OhifLink }}
              onClick={() => this.LogActivity("VIEW AI")}
              target="_blank"
            >
              <button
                className="dropdown-item "
                type="button"
                hidden={this.props.hiddenDelete}
              >
                A.I. Viewer
              </button>
            </Link>
            {this.props.role.meddream && (
              <Link
                style={{ textDecoration: "none" }}
                to={{
                  pathname: //tukar link - meddream
                    "https://demosvr.padimedical.com/stone-webviewer/index.html?study=" +
                    this.props.StudyInstanceUID,
                }}
                target="_blank"
              >
                <button
                  className="dropdown-item "
                  type="button"
                  hidden={this.props.hiddenDelete}
                >
                  OR Viewer (Trial) {/*tukar nama - MedDream (DEMO) to Orthanc Viewer (Trial) */}
                </button>
              </Link>
            )}

            {this.props.role.can_download_zip && (
              <a
                style={{ textDecoration: "none" }}
                href={this.props.downloadzip}
                target="_blank"
                onClick={() => this.LogActivity("DOWNLOAD ZIP")}
              >
                <button
                  className="dropdown-item "
                  type="button"
                  hidden={this.props.hiddenDelete}
                >
                  Download ZIP
                </button>
              </a>
            )}
          </Dropdown.Menu>
        </Dropdown>
      </Fragment>
    );
  };
}

const mapStateToProps = (state) => ({
  role: state.PadiMedical.roles,
});

export default connect(mapStateToProps, null)(ActionBoutonView);
