import { useEffect, useState } from "react";
import RequestFeatureTable from "./RequestFeatureTable";
import requestFeatureService from "../../services/requestFeature";
const RequestFeatureListPage = () => {
  const [data, setData] = useState([]);

  useEffect(()=>{
    requestFeatureService.getList()
    .then(resJson=>setData(resJson))
    .catch(err=>console.log(err))
  },[])
  return (
    <>
      <RequestFeatureTable tableData={data} />
    </>
  );
};
export default RequestFeatureListPage;
